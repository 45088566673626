import { FixedObject } from 'gatsby-image';
import React from 'react';
import 'swiper/css/bundle';
import { Citation, Json } from '../../types';
import BlogPostContent from '../blog/BlogPostContent';
import PageSection from '../ui/PageSection';
import UntitledButton from '../ui/UntitledButton';
import styles from './IntegrationContent.module.scss';
import IntegrationSlider from './IntegrationSlider';
import IntegrationSidebar from './IntegrationSidebar';

interface IntegrationContentProps {
  htmlAst: Json;
  name: string;
  website?: string;
  category?: string;
  setupUrl?: string;
  installSlug?: string;
  gradient?: string;
  screenshots?: {
    image: { childImageSharp: { fixed: FixedObject } };
    caption?: string;
  }[];
  citations?: Citation[];
}

const IntegrationContent = ({
  htmlAst,
  name,
  website,
  category,
  setupUrl,
  installSlug,
  gradient,
  screenshots,
  citations,
}: IntegrationContentProps) => {
  const installButton = installSlug && (
    <UntitledButton
      variant="primary"
      block
      target="_blank"
      to={`/app/admin/integrations/${installSlug}`}
      track={{ label: 'integration-install', library: name }}
    >
      Add to Slab
    </UntitledButton>
  );

  return (
    <PageSection>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <IntegrationSidebar
            name={name}
            website={website}
            category={category}
            setupUrl={setupUrl}
            installSlug={installSlug}
          />
        </div>
        <div className={styles.topButtonContainer}>{installButton}</div>
        {screenshots && (
          <div className={styles.screenshots}>
            <IntegrationSlider gradient={gradient} screenshots={screenshots} />
          </div>
        )}

        <div className={styles.content}>
          <BlogPostContent citations={citations} htmlAst={htmlAst} />
        </div>
        <div className={styles.bottomButtonContainer}>{installButton}</div>
      </div>
    </PageSection>
  );
};

export default IntegrationContent;
