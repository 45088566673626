import classNames from 'classnames';
import { FixedObject } from 'gatsby-image';
import React, { useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './IntegrationSlider.module.scss';
import IntegrationScreenshot from './IntegrationScreenshot';
import Button from '../ui/Button';

type screenshot = {
  image: { childImageSharp: { fixed: FixedObject } };
  caption?: string;
};

interface IntegrationSliderProps {
  gradient?: string;
  screenshots?: screenshot[];
}

const IntegrationSlider = ({
  gradient,
  screenshots,
}: IntegrationSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasGradient, setGradient] = useState(false);

  if (!screenshots?.length) {
    return null;
  }

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const isBottom =
      Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
      1;

    setGradient(!isBottom);
  };

  const hasCaption = screenshots.some((screenshot) => screenshot.caption);

  return (
    <div className={styles.container}>
      <div className={styles.mobile}>
        <Swiper
          className={classNames({
            [styles.multiScreens]: screenshots.length > 1,
          })}
          spaceBetween={24}
          slidesPerView={screenshots.length > 1 ? 1.2 : 1}
          slideToClickedSlide
          slidesOffsetAfter={screenshots.length > 1 ? 24 : 0}
          grabCursor
          mousewheel
        >
          {screenshots?.map((screenshot) => (
            <SwiperSlide key={screenshot.image.childImageSharp.fixed.src}>
              <IntegrationScreenshot
                screenshot={screenshot.image}
                gradient={gradient}
              />
              <div className={styles.caption}>{screenshot?.caption}</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div
        className={classNames(styles.desktop, {
          [styles.single]: screenshots.length === 1,
        })}
      >
        <div className={styles.screenshotContainer}>
          <IntegrationScreenshot
            screenshot={screenshots[currentIndex].image}
            gradient={gradient}
          />
        </div>
        <div className={styles.sliderLayoutPlaceholder}>
          <div className={styles.slider} onScroll={handleScroll}>
            {screenshots?.map((screenshot, index) => (
              <div
                key={screenshot.image.childImageSharp.fixed.src}
                className={classNames(styles.sliderScreenshot, {
                  [styles.active]: currentIndex === index,
                })}
              >
                <Button unstyled block onClick={() => setCurrentIndex(index)}>
                  <IntegrationScreenshot
                    screenshot={screenshot.image}
                    gradient={gradient}
                    thumbnail
                  />
                </Button>
              </div>
            ))}
          </div>
          <div
            className={classNames(styles.sliderGradient, {
              [styles.active]: hasGradient,
            })}
          />
        </div>
        {hasCaption && (
          <div className={styles.caption}>
            {screenshots[currentIndex]?.caption}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationSlider;
