import React from 'react';
import arrowCornerRight from '../../images/integrations/arrow-corner-right.svg';
import arrowLeft from '../../images/integrations/arrow-left.svg';
import UntitledButton from '../ui/UntitledButton';
import Link from '../ui/Link';
import styles from './IntegrationSidebar.module.scss';

interface IntegrationSidebarProps {
  name: string;
  website?: string;
  category?: string;
  setupUrl?: string;
  installSlug?: string;
}

const IntegrationSidebar = ({
  name,
  website,
  category,
  setupUrl,
  installSlug,
}: IntegrationSidebarProps) => (
  <div>
    <div className={styles.navBack}>
      <Link className={styles.backLink} to="/integrations">
        <div className={styles.arrowLeft}>
          <img src={arrowLeft} alt="" />
        </div>
        Back
      </Link>
    </div>
    <div className={styles.details}>
      {website && (
        <div className={styles.item}>
          <strong>Website</strong>
          <a
            href={website}
            className={styles.link}
            rel="noreferrer"
            target="_blank"
          >
            {new URL(website).hostname}
            <div className={styles.arrowCornerRight}>
              <img src={arrowCornerRight} alt="" />
            </div>
          </a>
        </div>
      )}
      {category && (
        <div className={styles.item}>
          <strong>Category</strong>
          <p>{category}</p>
        </div>
      )}
      {setupUrl && (
        <div className={styles.item}>
          <strong>Documentation</strong>
          <a href={setupUrl} className={styles.link}>
            {name} Docs
          </a>
        </div>
      )}
      <div className={styles.item}>
        <strong>Contact info</strong>
        <a href="mailto:support@slab.com" className={styles.link}>
          Email Slab
        </a>
      </div>
    </div>
    <div className={styles.buttonContainer}>
      {installSlug && (
        <UntitledButton
          variant="primary"
          block
          target="_blank"
          to={`/app/admin/integrations/${installSlug}`}
          track={{ label: 'integration-install', library: name }}
        >
          Add to Slab
        </UntitledButton>
      )}
    </div>
  </div>
);

export default IntegrationSidebar;
