import classNames from 'classnames';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import styles from './IntegrationScreenshot.module.scss';

type screenshot = { childImageSharp: { fixed: FixedObject } };

interface IntegrationScreenshotProps {
  screenshot: screenshot;
  thumbnail?: boolean;
  gradient?: string;
}

const IntegrationScreenshot = ({
  screenshot,
  thumbnail,
  gradient,
}: IntegrationScreenshotProps) => {
  const bgElementClassName = classNames(
    styles.background,
    gradient ? styles[gradient] : null,
  );

  return (
    <div className={styles.screenshot}>
      <img
        className={classNames(
          styles.image,
          thumbnail ? styles.thumbnail : null,
        )}
        src={screenshot.childImageSharp.fixed.src}
        alt=""
      />
      <div className={bgElementClassName} />
    </div>
  );
};

export default IntegrationScreenshot;
