import React from 'react';
import { IntegrationService } from '../../types';
import PageSection from '../ui/PageSection';
import IntegrationCard from './IntegrationCard';
import styles from './IntegrationSimilar.module.scss';

interface IntegrationSimilarProps {
  services: IntegrationService[];
}

const IntegrationSimilar = ({ services }: IntegrationSimilarProps) => (
  <PageSection>
    <div className={styles.container}>
      <h2>Similar services</h2>
      <div className={styles.grid}>
        {services.map((service) => (
          <IntegrationCard key={service.name} {...service} />
        ))}
      </div>
    </div>
  </PageSection>
);

export default IntegrationSimilar;
