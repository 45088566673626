import { graphql } from 'gatsby';
import React from 'react';
import IntegrationContent from '../components/integration/IntegrationContent';
import IntegrationHeader from '../components/integration/IntegrationHeader';
import IntegrationSimilar from '../components/integration/IntegrationSimilar';
import MarketingLayout from '../components/layout/MarketingLayout';
import UntitledStyle from '../components/layout/UntitledStyle';
import { IntegrationsPageData } from '../pages/integrations';
import { IntegrationMarkdownRemark } from '../types';
import allIntegrations from '../utils/allIntegrations';

interface IntegrationTemplateData extends IntegrationsPageData {
  markdown: IntegrationMarkdownRemark;
  icon?: {
    publicURL: string;
  };
}

interface IntegrationTemplateProps {
  data: IntegrationTemplateData;
}

const IntegrationTemplate = ({ data }: IntegrationTemplateProps) => {
  const {
    markdown: {
      htmlAst,
      frontmatter: {
        name,
        website,
        category,
        setup,
        install,
        similar,
        description,
        gradient,
        screenshots,
        citations,
      },
    },
    icon,
  } = data;

  const similarServiceSlugs = similar ? similar.split(/,\s*/) : [];
  const similarServices = allIntegrations(data).filter(
    ({ slug }) => slug && similarServiceSlugs.includes(slug),
  );

  return (
    <MarketingLayout
      navbarBackground="gray"
      noFooterPadding
      title={`${name} Integration`}
    >
      <IntegrationHeader
        icon={icon?.publicURL}
        name={name}
        description={description}
      />
      <UntitledStyle>
        <IntegrationContent
          htmlAst={htmlAst}
          name={name}
          website={website}
          category={category}
          setupUrl={setup}
          installSlug={install}
          screenshots={screenshots}
          gradient={gradient}
          citations={citations}
        />
        {similarServices.length > 0 && (
          <IntegrationSimilar services={similarServices} />
        )}
      </UntitledStyle>
    </MarketingLayout>
  );
};

export default IntegrationTemplate;

export const query = graphql`
  query ($slug: String!) {
    markdown: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        name
        description
        website
        category
        setup
        install
        similar
        gradient
        screenshots {
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          caption
        }
        citations {
          id
          name
          title
          link
          quoteColor
          picture {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    icon: file(
      relativeDirectory: { eq: $slug }
      sourceInstanceName: { eq: "integrations" }
      name: { eq: "icon" }
    ) {
      publicURL
    }
    ...IntegrationsQueryFragment
  }
`;
