import { Link } from 'gatsby';
import React from 'react';
import styles from './IntegrationCard.module.scss';

const DEFAULT_DESCRIPTION = 'Designed to keep your team on the same page.';
interface IntegrationCardProps {
  name: string;
  icon: string;
  slug?: string;
  description?: string;
  premium?: boolean;
  html?: string;
}

const IntegrationCard = ({
  name,
  icon,
  slug,
  description,
  premium,
  html,
}: IntegrationCardProps) => {
  const card = (
    <div className={styles.card}>
      <div className={styles.titleRow}>
        <img src={icon} alt={`${name} icon`} />
        <h3 className={styles.name}>{name}</h3>
        {premium && (
          <span className={`${styles.tag} ${styles.premium}`}>premium</span>
        )}
      </div>

      <div className={styles.descriptionRow}>
        {description || DEFAULT_DESCRIPTION}
      </div>
    </div>
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return html ? <Link to={`/integrations/${slug}/`}>{card}</Link> : card;
};

export default IntegrationCard;
